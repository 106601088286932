import { css } from '@linaria/core';
import { RowRoot } from '@ard-online/component-styleguide';
import { Root as ClassicStageRoot } from '@ard-online/component-styleguide/dist/components/ClassicStage/styles';

export const Root = {
	base: css`
		margin-bottom: 50px;

		.${ClassicStageRoot.base} {
			> div {
				overflow: hidden;
			}
		}

		.${RowRoot.base} {
			margin-top: 0;
		}
	`,
};
