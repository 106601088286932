import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Column, Row, Typo } from '@ard-online/component-styleguide';
import { Datetime, Label, Root } from './styles';
import useFetch from '../../../services/fetch/useFetch';
import { mapXMLBreakingNews } from '../../../services/fetch';
import { sendTeaserClick, sendTeaserImpression } from '../../../services/tracking';
import { PAGE_TITLES, WIDGET_TYPES } from '../../../configs/types';
import { useInView } from 'react-intersection-observer';
import { BreakingNewsItem } from '../../../services/fetch/mapXMLBreakingNews';
import { cx } from '@linaria/core';

type Props = {
	feed: string;
};

function BreakingNewsWidget({ feed }: Props) {
	/** Variables */
	const data: Array<BreakingNewsItem> = useFetch(feed, 'xml', mapXMLBreakingNews);

	const [mostRecentNews, setMostRecentNews] = useState<BreakingNewsItem | null>(null);

	const [rootRef, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const onTeaserClickMemo = useCallback(() => {
		if (mostRecentNews) {
			sendTeaserClick(PAGE_TITLES.HOME, {
				widgetTitle: 'Eilmeldung',
				widgetIndex: -1,
				widgetId: WIDGET_TYPES.BREAKING_NEWS,
				teaserTitle: mostRecentNews.title,
				teaserIndex: 0,
				teaserHref: mostRecentNews.href,
			});
		}
	}, [mostRecentNews]);

	const onTeaserVisibleMemo = useCallback(() => {
		if (mostRecentNews) {
			sendTeaserImpression(PAGE_TITLES.HOME, {
				widgetTitle: 'Eilmeldung',
				widgetIndex: -1,
				widgetId: WIDGET_TYPES.BREAKING_NEWS,
				teaserTitle: mostRecentNews.title,
				teaserIndex: 0,
				teaserHref: mostRecentNews.href,
			});
		}
	}, [mostRecentNews]);

	function formatNumber(number: number) {
		if (number < 10) {
			return `0${number}`;
		} else {
			return number;
		}
	}

	function getDate() {
		if (mostRecentNews) {
			const date = new Date(mostRecentNews.date);
			return `${formatNumber(date.getDate())}.${formatNumber(
				date.getMonth() + 1,
			)}.${date.getFullYear()} ・ ${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())} Uhr`;
		}
	}

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		if (inView && onTeaserVisibleMemo) {
			onTeaserVisibleMemo();
		}
	}, [inView, onTeaserVisibleMemo]);

	useEffect(() => {
		if (data?.length) {
			data.sort((leftItem, rightItem) => new Date(rightItem.date).getTime() - new Date(leftItem.date).getTime());
			let breakingNewsItem: BreakingNewsItem | null = data[0];
			const expirationTime = new Date(new Date(breakingNewsItem.date).getTime() + 60 * 60 * 1000).getTime(); // 1 hour after publication

			if (new Date().getTime() > expirationTime) {
				breakingNewsItem = null;
			}
			setMostRecentNews(breakingNewsItem);
		} else {
			setMostRecentNews(null);
		}
	}, [data]);

	/** JSX */
	return (
		<>
			{mostRecentNews && (
				<section className={cx(Root.base)} ref={rootRef}>
					<Row justifyContent="center" mx={0} my={0}>
						<Column all={11} xs={11} xl={8}>
							<div className={cx(Label.base)}>
								<Icon name="megaphone" type="regular" /> EILMELDUNG
								<span className={cx(Datetime.base)}>{getDate()}</span>
							</div>
							<a href={mostRecentNews.href} onClick={onTeaserClickMemo} target="_blank" rel="noreferrer">
								<h2 className={cx(Typo.base, Typo.heading02)}>{mostRecentNews.title}</h2>
							</a>
							<p className={cx(Typo.base, Typo.body02)}>{mostRecentNews.teasertext}</p>
						</Column>
					</Row>
				</section>
			)}
		</>
	);
}

export default BreakingNewsWidget;
