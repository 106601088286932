import { Button, ChannelLogo, Column, Image, Navbar, Row } from '@ard-online/component-styleguide';
import React, { useEffect, useMemo, useState } from 'react';
import MTAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.mt.jpg';
import TSAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.ts.jpg';
import SPAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.sp.jpg';
import ATAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.at.jpg';
import KikaAppIcon from '@ard-online/component-styleguide/dist/assets/images/logos/png/appIcon/appIcon.kika.jpg';
import type { AnyFunction, AnyObject } from '../../../types';
import { Item } from '../../../types';
import { Header, HeaderShadow, Logo, ShadowAnimation } from './styles';
import { Link } from 'react-router-dom';
import { cx } from '@linaria/core';
import { BUTTON_TYPES } from '@ard-online/component-styleguide/dist/components/Button/Button';

type Props = {
	data?: AnyObject;
};

const icons: AnyObject = {
	mediathek: MTAppIcon,
	audiothek: ATAppIcon,
	tagesschau: TSAppIcon,
	sportschau: SPAppIcon,
	kika: KikaAppIcon,
};

enum urls {
	mediathek = 'https://www.ardmediathek.de',
	audiothek = 'https://www.ardaudiothek.de',
	tagesschau = 'https://www.tagesschau.de',
	sportschau = 'https://www.sportschau.de',
	kika = 'https://www.kika.de/ardverteiler',
}

function HeaderWidget({ data }: Props) {
	/** Variables */
	const [scrolled, setScrolled]: [boolean, AnyFunction] = useState(false);

	function onScroll() {
		if (window.scrollY > 0) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	}

	const listenerOptions: AddEventListenerOptions = {
		passive: true,
	};

	function mapName(name: string) {
		switch (name) {
			case 'mediathek':
				return 'Mediathek';

			case 'tagesschau':
				return 'Tagesschau';

			case 'sportschau':
				return 'Sportschau';

			case 'audiothek':
				return 'Audiothek';

			case 'kika':
				return 'KiKA';

			default:
				return '';
		}
	}

	const big5ItemsMemo = useMemo(() => {
		if (data) {
			return data
				.map((item: Item) => {
					if (item.id === 'topics') {
						return undefined;
					} else {
						return {
							iconLeft: (
								<Image
									src={icons[item.id]}
									ratio="1x1"
									isLoadingEagerly
									alt={`${mapName(item.id)} Logo`}
								/>
							),
							text: mapName(item.id),
							href: urls[item.id as keyof typeof urls],
							type: 'function',
							target: '_blank',
							rel: 'noopener noreferrer',
						};
					}
				})
				.filter(Boolean);
		}
	}, [data]);

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		window.addEventListener('scroll', onScroll, listenerOptions);
		return () => {
			window.removeEventListener('scroll', onScroll, listenerOptions);
		};
	}, []);

	/** JSX */
	return (
		<>
			<header className={cx(Header.base)}>
				<Row justifyContent="center" mx={0} my={0}>
					<Column all={11} xs={11} xl={8} px={0}>
						<Row mx={0} my={0}>
							<Column all={12} xs={6} p={0}>
								<div className={cx(Logo.base)}>
									<Button
										href="/"
										onClick={() => window && window.scrollTo(0, 0)}
										text="ARD"
										iconLeft={<ChannelLogo channel="ard" />}
										isIconOnly={true}
										as={Link}
										type={BUTTON_TYPES.TEXT}
									/>
								</div>
							</Column>
							<Column all={12} xs={6} p={0}>
								{big5ItemsMemo && (
									<Navbar
										buttonType={BUTTON_TYPES.TAB}
										alignment="right"
										accessibility="menu"
										hasControlledItems
										items={big5ItemsMemo}
									/>
								)}
							</Column>
						</Row>
					</Column>
				</Row>
			</header>
			<div className={cx(HeaderShadow.base, scrolled && ShadowAnimation.base)} />
		</>
	);
}

export default HeaderWidget;
