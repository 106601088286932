import { css } from '@linaria/core';
import { Media, Typo } from '@ard-online/component-styleguide';

export const GlobalStyle = {
	base: css`
		/* stylelint-disable-next-line */
		:global() {
			body {
				font-family: var(--typography-family-font-family), sans-serif;
				background-repeat: no-repeat;
				color: rgb(var(--text-color-body-01));
				background-color: rgb(var(--background-color-base));
				margin: 0;
				transition: 0.3s background-color;
			}

			a,
			p {
				color: rgb(var(--text-color-body-01));
			}

			&:active,
			&:hover,
			&:visited {
				outline: none;
			}

			&:focus {
				outline: 2px solid rgb(var(--text-color-body-01));
				outline-offset: -2px;
			}
		}
	`,
};
export const Root = {
	base: css`
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	`,
};
export const ContentWrapper = {
	base: css`
		flex-grow: 1;
	`,
};
export const PageWrapper = {
	base: css`
		margin-top: 130px;

		${Media('max', 'xs')} {
			margin-top: 180px;
		}

		.${Typo.heading01} {
			text-align: center;
		}
	`,
};
export const Text = {
	base: css`
		padding-left: 15px;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		justify-items: flex-start;

		.${Typo.body02} {
			margin-bottom: 20px;
		}
	`,
};
