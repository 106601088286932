import { css } from '@linaria/core';
import { Media, RowRoot, ColumnRoot } from '@ard-online/component-styleguide';
import {
	Root as NavbarRoot,
	ButtonElement as NavbarButtonElement,
} from '@ard-online/component-styleguide/dist/components/Navbar/styles';
import {
	Root as ButtonRoot,
	IconWrapper as ButtonIconWrapper,
} from '@ard-online/component-styleguide/dist/components/Button/styles';
import { Root as ImageRoot } from '@ard-online/component-styleguide/dist/components/Image/styles';

export const Header = {
	base: css`
		&& {
			width: 100%;
			padding: 0;
			margin: 0;
			z-index: 20;
			position: fixed;
			top: 0;
			height: 115px;
			background-color: rgb(var(--background-color-base));

			${Media('max', 'xs')} {
				height: 160px;
			}

			.${RowRoot.base} {
				display: flex;
				align-items: center;
				height: 70%;

				.${ColumnRoot.base} {
					${Media('max', 'xs')} {
						padding: 0;
					}
				}
			}

			.${NavbarRoot.base} {
				> ul {
					${Media('max', 'xs')} {
						justify-content: center;
						margin-top: -10px;
						margin-bottom: 5px;
					}
				}

				.${ButtonRoot.base} {
					overflow: visible;
				}

				.${NavbarButtonElement.base} {
					.${ButtonRoot.base} {
						justify-content: center;

						.${ButtonIconWrapper.base} {
							border-radius: 48px;
							overflow: hidden;
							box-shadow:
								0 3px 6px rgba(0, 0, 0, 0.16),
								0 3px 6px rgba(0, 0, 0, 0.23) !important;

							.${ImageRoot.base} {
								width: 48px;
								height: 48px;
							}
						}

						span {
							padding: 0;
							font-size: 0.65rem;
						}
					}
				}
			}
		}
	`,
};
export const HeaderShadow = {
	base: css`
		position: fixed;
		top: 0;
		height: 115px;
		width: 100%;
		box-shadow:
			0 3px 6px rgba(0, 0, 0, 0.16),
			0 3px 6px rgba(0, 0, 0, 0.23);
		opacity: 0;
		z-index: 9;
		pointer-events: none;

		${Media('max', 'xs')} {
			height: 160px;
		}
	`,
};

export const Logo = {
	base: css`
		&&& {
			padding: 15px 0 15px 10px;
			display: inline;
			transition: height 0.3s ease-in-out;

			svg {
				width: auto;
				height: 58px;
				background: transparent;
				fill: rgb(var(--text-color-body-01)) !important;

				> path {
					fill: rgb(var(--text-color-body-01)) !important;
				}
			}

			a {
				transition: transform 0.3s;

				&:hover {
					background: transparent !important;
					transform: scale(1.1);
				}
			}

			${Media('max', 'xs')} {
				display: flex;
				justify-content: center;
				height: 50px;
				margin: -10px auto 0;
			}
		}
	`,
};

export const ShadowAnimation = {
	base: css`
		animation: fadeIn 250ms var(--global-ease-in-out) both;
		animation-delay: 100ms;
		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	`,
};
