import { css } from '@linaria/core';
import { Typo } from '@ard-online/component-styleguide';
import { Root as SwitchRoot } from '@ard-online/component-styleguide/dist/components/Switch/styles';

export const List = {
	base: css`
		list-style: none;
		display: block;
		padding: 0;
		margin: 0 0 50px;
	`,
};
export const Listitem = {
	base: css`
		list-style-type: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: 10px 0;
		margin: 0;
		border-bottom: 1px solid rgba(var(--text-color-body-01), 0.6);

		&:first-child {
			border-top: 1px solid rgba(var(--text-color-body-01), 0.6);
		}

		.${Typo.body02} {
			margin-right: 10px;
		}

		.${SwitchRoot.base} {
			align-items: baseline;
		}
	`,
};
