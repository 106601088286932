import React, { useCallback, useEffect } from 'react';
import { ClassicStage, Column, Loading, Row, Typo } from '@ard-online/component-styleguide';
import { Root } from './styles';
import { Text } from '../../app/styles';
import useFetch from '../../../services/fetch/useFetch';
import { usePictureCredits } from '../../../services/pictureCredits/PictureCreditService';
import { sendTeaserClick, sendTeaserImpression } from '../../../services/tracking';
import { PAGE_TITLES, TEASER_TYPES, WIDGET_TYPES } from '../../../configs/types';
import { mapJSONItemsAT, mapJSONItemsMT, mapXMLItems } from '../../../services/fetch';
import { Widget } from '../../../types';
import { Props } from '@ard-online/component-styleguide/dist/components/Teaser/Teaser';
import { cx } from '@linaria/core';

type SwiperItem = Props & { image: { source: string } };

type teaserProps = {
	teaserTitle: string;
	teaserIndex: number;
	teaserHref: string;
	teaserType?: TEASER_TYPES;
};

function SwiperWidget({ id, headline, subTitle, href, feed, widgetIndex }: Widget) {
	/** Variables */
	const { addPictureCredit } = usePictureCredits();

	const onTeaserClickMemo = useCallback(
		(e: teaserProps) => {
			sendTeaserClick(PAGE_TITLES.HOME, {
				widgetTitle: headline,
				widgetIndex,
				widgetId: id,
				...e,
			});
		},
		[headline, widgetIndex],
	);

	const onTeaserVisibleMemo = useCallback(
		(e: teaserProps) => {
			sendTeaserImpression(PAGE_TITLES.HOME, {
				widgetTitle: headline,
				widgetIndex,
				widgetId: id,
				...e,
			});
		},
		[headline, widgetIndex],
	);

	let type;
	let mapTo;

	if (id === WIDGET_TYPES.MEDIATHEK) {
		type = 'json';
		mapTo = mapJSONItemsMT(href, onTeaserClickMemo, onTeaserVisibleMemo);
	} else if (id === WIDGET_TYPES.AUDIOTHEK) {
		type = 'json';
		mapTo = mapJSONItemsAT(onTeaserClickMemo, onTeaserVisibleMemo);
	} else {
		type = 'xml';
		mapTo = mapXMLItems(onTeaserClickMemo, onTeaserVisibleMemo);
	}

	const items: Array<SwiperItem> = useFetch(feed, type, mapTo, widgetIndex, id);

	/** Construktor-Hook & Effect-Hooks */
	useEffect(() => {
		items.forEach((item) =>
			addPictureCredit({
				title: item.title ? item.title : item.shortTitle || '',
				source: item.image.source,
				widgetIndex,
			}),
		);
	}, [items]);

	/** JSX */
	return (
		<>
			<section className={cx(Root.base)} id={`${id}Root`}>
				<Row justifyContent="center">
					<Column all={11} xl={8} p={0}>
						<div className={cx(Text.base)}>
							<a href={href} target="_blank" rel="noreferrer">
								<h2 className={cx(Typo.base, Typo.heading02)}>{headline}</h2>
							</a>
							<p className={cx(Typo.base, Typo.body02)}>{subTitle}</p>
						</div>
					</Column>
					<Column p={0}>
						{items.length ? (
							<ClassicStage hasVisibleTitle={false} items={items} hasAutoplay={false} />
						) : (
							<Loading height="550" />
						)}
					</Column>
				</Row>
			</section>
		</>
	);
}

export default SwiperWidget;
